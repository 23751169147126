<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import userService from '@/services/user/user'

import MenuBar from './components/MenuBar.vue'
import NewPasswordForm from './components/NewPasswordForm.vue'

export default {
  mixins: [toastMixins],
  components: {
    MenuBar,
    NewPasswordForm
  },
  data() {
    return {
      userId: null,
      userLogonMaster: {},
      errMsg: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    })
  },
  created() {
    this.userId = this.$route.params.userId
    this.fetchCurrentUserLogonMaster()
  },
  methods: {
    async fetchCurrentUserLogonMaster() {
      try {
        this.isLoading = true
        const res = await userService.getUserLogonMaster(this.userId)
        this.userLogonMaster = res.data.data
      } catch (err) {
        this.errMsg =
          err?.response?.data?.thMessage ||
          'เกิดข้อผิดพลาดในระบบการดึงข้อมูลผู้ใช้งาน'
      } finally {
        this.isLoading = false
      }
    },
    async setPasswordSubmittedHandler(event) {
      const { args } = event

      try {
        this.isLoading = true
        await userService.setPassword(this.userId, args)
        this.mxDisplaySuccessMessage('เปลี่ยนรหัสผ่านสำเร็จแล้ว')
        setTimeout(() => {
          this.$router.push({
            name: 'Admin.UserLogonMaster.Detail',
            params: { userId: this.userId }
          })
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg =
          err?.response?.data?.thMessage ||
          'เกิดข้อผิดพลาดในระบบการเปลี่ยนรหัสผ่านผู้ใช้งาน'
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="เปลี่ยนรหัสผ่านผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- contents -->
    <b-row>
      <b-col>
        <new-password-form
          :isLoadingProp="isLoading"
          :userLogonMasterProp="userLogonMaster"
          @setPasswordSubmitted="setPasswordSubmittedHandler"
        ></new-password-form>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
