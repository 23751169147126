<script>
import _ from 'lodash'

import AppCard from '@/components/card/Card.vue'

export default {
  components: { AppCard },
  props: {
    isLoadingProp: {
      type: Boolean,
      required: false,
      default: false
    },
    userLogonMasterProp: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      password: null,
      passwordConfirm: null
    }
  },
  methods: {
    shouldSaveButtonEnable() {
      const isUndefinedNullOrEmptyString = (v) =>
        _.isNil(v) || (_.isString(v) && v.trim().length === 0)

      if (
        isUndefinedNullOrEmptyString(this.password) ||
        isUndefinedNullOrEmptyString(this.passwordConfirm) ||
        this.password !== this.passwordConfirm
      ) {
        return false
      }

      return true
    },
    getNewDataArgs() {
      const args = {}

      const dataToBeProceed = [
        {
          key: 'password',
          value: this.password
        },
        {
          key: 'passwordConfirm',
          value: this.passwordConfirm
        }
      ]

      dataToBeProceed.forEach((el) => {
        const { key, value } = el
        if (
          _.isNil(value) ||
          (_.isString(value) && value.trim().length === 0)
        ) {
          args[key] = null
        } else if (_.isString(value)) {
          args[key] = value.trim()
        } else {
          args[key] = value
        }
      })

      return args
    },
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          const dataArgs = this.getNewDataArgs()
          this.$emit('setPasswordSubmitted', { args: dataArgs })
          break
        case 'CANCEL':
          this.$router.back()
          break
        default:
          break
      }
    }
  }
}
</script>

<template>
  <app-card>
    <!-- title / buttons -->
    <b-row align-v="center">
      <!-- title -->
      <b-col cols="12" md="4">
        <div class="d-flex justify-content-center justify-content-md-start">
          <span class="font-weight-bold text-lg">ตั้งรหัสผ่านผู้ใช้งาน</span>
        </div>
      </b-col>
      <!-- buttons -->
      <b-col cols="12" md="8">
        <div
          class="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0"
        >
          <b-button
            class="px-5 mr-3"
            variant="primary"
            @click="buttonClicked('CONFIRM')"
            :disabled="isLoadingProp || !shouldSaveButtonEnable()"
          >
            <b-spinner variant="light" small v-if="isLoadingProp"></b-spinner>
            <span v-else>บันทึก</span>
          </b-button>
          <b-button
            class="px-5"
            variant="outline-primary"
            @click="buttonClicked('CANCEL')"
            :disabled="isLoadingProp"
          >
            ยกเลิก
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <b-row>
      <b-col>
        <hr class="my-4" />
      </b-col>
    </b-row>

    <!-- input fields -->
    <b-row>
      <!-- userName -->
      <b-col cols="12" class="mt-3">
        <b-row align-v="center">
          <b-col cols="3" md="2">
            <span>ผู้ใช้งาน</span>
          </b-col>
          <b-col cols="9" md="8" lg="6">
            <span class="font-weight-bold"
              >{{ userLogonMasterProp.userName || '-' }}
              {{
                userLogonMasterProp.userId
                  ? `(${userLogonMasterProp.userId})`
                  : ''
              }}</span
            >
          </b-col>
        </b-row>
      </b-col>

      <!-- password -->
      <b-col cols="12" class="mt-3">
        <b-row align-v="center">
          <b-col cols="3" md="2">
            <span>รหัสผ่านใหม่</span>
          </b-col>
          <b-col cols="9" md="8" lg="6">
            <b-input
              type="password"
              v-model="password"
              :disabled="isLoadingProp"
            ></b-input>
          </b-col>
        </b-row>
      </b-col>

      <!-- password confirm -->
      <b-col cols="12" class="mt-3">
        <b-row align-v="center">
          <b-col cols="3" md="2">
            <span>ยืนยันรหัสผ่านใหม่</span>
          </b-col>
          <b-col cols="9" md="8" lg="6">
            <b-input
              type="password"
              v-model="passwordConfirm"
              :disabled="isLoadingProp"
            ></b-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </app-card>
</template>

<style></style>
